import {MDCTopAppBar} from '@material/top-app-bar';
import {MDCDataTable} from '@material/data-table';
import {MDCDialog} from '@material/dialog';
import {MDCTextField} from '@material/textfield';
import {MDCMenu} from '@material/menu';
import {MDCFormField} from '@material/form-field';
import {MDCTextFieldHelperText} from '@material/textfield/helper-text';
import {MDCCheckbox} from '@material/checkbox';
import {MDCSelect} from '@material/select';
import {MDCTabBar} from '@material/tab-bar';
import {MDCList} from '@material/list';
import {MDCRipple} from '@material/ripple';
import {MDCSnackbar} from '@material/snackbar';
import {MDCSwitch} from '@material/switch';

// C3.js
import c3 from 'c3';

if (typeof chartSettings !== 'undefined') {
  chartSettings.forEach(chartSetting => {
    charts.push(c3.generate(chartSetting));
  });
}


// header
const topAppBarElement = document.querySelector('.mdc-top-app-bar');
const topAppBar = new MDCTopAppBar(topAppBarElement);

// data table
const dataTableElements = document.querySelectorAll('.mdc-data-table');
if (dataTableElements) {
  dataTableElements.forEach(dataTableElement => {
    const dataTable = new MDCDataTable(dataTableElement);
  });
}

// dialog
const dialogElements = document.querySelectorAll('.mdc-dialog');
if (dialogElements) {
  dialogElements.forEach(dialogElement => {

    const dialogID = dialogElement.getAttribute('id');
    const triggers = document.querySelectorAll(`.js-openModal[aria-controls="${ dialogID }"]`);

    const dialog = new MDCDialog(dialogElement);

    const textFieldElements = dialogElement.querySelectorAll('.mdc-text-field');
    if (textFieldElements) {
      textFieldElements.forEach(textFieldElement => {
        dialogTextFields.push(new MDCTextField(textFieldElement));
      });
    }

    dialog.listen('MDCDialog:closed', action => {
      if (action.detail.action == 'accept') {
        onAcceptDialog(action);
      }
      if (action.detail.action == 'close' && typeof onCloseDialog == 'function') {
        onCloseDialog(action);
      }
    });

    // triggers for open a dialog
    triggers.forEach(trigger => {
      trigger.addEventListener('click', () => {
        const ariaControls = trigger.getAttribute('aria-controls')
        dialog.open();

        onClickTriggerDialog(trigger, ariaControls, dialogElement);
      })
    });

    // Change Breading Number

  });
}

// select
const selectElements = document.querySelectorAll('.mdc-select');
if (selectElements) {
  selectElements.forEach(selectElement => {
    const input = selectElement.querySelector('input');
    const select = new MDCSelect(selectElement);
    if (input) {
      select.listen('MDCSelect:change', event => {
        input.value = event.detail.value;
      });
    }
  });
}

// checkbox
const formFieldElements = document.querySelectorAll('.mdc-form-field');
if (formFieldElements) {
  formFieldElements.forEach(formFieldElement => {
    const checkboxElement = formFieldElement.querySelector('.mdc-checkbox');
    const formField = new MDCFormField(formFieldElement);
    if (checkboxElement) {
      const checkbox = new MDCCheckbox(checkboxElement);
      formField.input = checkbox;
    }
  });
}

const tabBarElements = document.querySelectorAll('.mdc-tab-bar');
if (tabBarElements) {
  tabBarElements.forEach(tabBarElement => {
    const tabBar = new MDCTabBar(tabBarElement);
    const tabs = tabBarElement.querySelectorAll('.mdc-tab');
    const tabPanels = [];
    tabs.forEach(tab => {
      const tabPanel = document.getElementById(tab.getAttribute('aria-controls'));
      if (tabPanel) {
        tabPanels.push(tabPanel);
      }
    });
    changeTab(tabBarElement.querySelector('.mdc-tab--active'));
    tabBar.listen('MDCTabBar:activated', event => {
      const tab = tabBarElement.querySelector(`.mdc-tab:nth-child(${ event.detail.index + 1 })`);
      changeTab(tab);
    });

    function changeTab (tab) {
      const targetPanel = document.getElementById(tab.getAttribute('aria-controls'));
      if (tabPanels.length) {
        tabPanels.forEach(tabPanel => {
          if (tabPanel == targetPanel) {
            if (tabPanel.tagName == 'TBODY') {
              tabPanel.style.setProperty('display', 'table-row-group');
            } else {
              tabPanel.style.setProperty('display', 'block');
            }
          } else {
            tabPanel.style.setProperty('display', 'none');
          }
        });
      }
    }
  });
}

const menuElements = document.querySelectorAll('.mdc-menu');
if (menuElements) {
  menuElements.forEach(menuElement => {
    const menu = new MDCMenu(menuElement);
    const menuTriggers = document.querySelectorAll(`.js-openMenu[aria-controls="${ menuElement.id }"]`);
    menuTriggers.forEach(menuTrigger => {
      menuTrigger.addEventListener('click', () => {
        menu.open = true;
        menu.setFixedPosition(true);
      });
    });
  });
}

const textFieldElements = document.querySelectorAll('div:not(.mdc-dialog) .mdc-text-field');
if (textFieldElements) {
  textFieldElements.forEach(textFieldElement => {
    const textField = new MDCTextField(textFieldElement);
  });
}

window.addTextField = function (textFieldElement) {
  const textField = new MDCTextField(textFieldElement);
}

const selector = '.mdc-button, .mdc-icon-button, .mdc-card__primary-action';
const ripples = [].map.call(document.querySelectorAll(selector), function(el) {
  return new MDCRipple(el);
});


const snackbarElements = document.querySelectorAll('.mdc-snackbar');
if (snackbarElements.length) {
  snackbarElements.forEach(snackbarElement => {
    const snackbar = new MDCSnackbar(snackbarElement);
    snackbars[snackbarElement.id] = snackbar;
  });
  isSnackbarsReady = true;
}

const switchElements = document.querySelectorAll('.mdc-switch');
if (switchElements) {
  switchElements.forEach(switchElement => {
    const switchControl = new MDCSwitch(switchElement);
  });
}